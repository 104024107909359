<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
      lg="4"
      md="4"
      xl="4"
      offset-lg="4"
      offset-md="4"
      offset-xl="4"
    >
      <v-card class="elevation-1" :loading="formLoading" :disabled="formLoading">
        <v-card-title>Attendance Summary</v-card-title>
        <v-card-text>
          <p class="mb-4 text-5">
            <v-icon>{{icon.info}}</v-icon> Select parameter then click generate.</p>
          <v-form>

            <!-- view As -->
            <v-radio-group
              v-model="viewAs"
              row
              class="pt-0 mt-0"
            >
              <v-radio
                label="PDF"
                value="pdf"
              ></v-radio>
              <v-radio
                label="Excel"
                value="excel"
              ></v-radio>
            </v-radio-group>

            <!-- Particular -->
            <v-autocomplete
              v-model="particular"
              :items="[
                { value: 'attendance-dtr-semi-monthly', text: 'DTR - Semi Monthly' },
                { value: 'attendance-dtr-monthly', text: 'DTR - Monthly' },
                { value: 'attendance-monthly-summary', text: 'Monthly Summary' },
              ]"
              outlined
              dense
              clearable
              label="Particular"
              :class="formErrorFields.particularErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.particularErrorMessage"
              @keydown="formError().remove('particular', formErrorFields)"
            ></v-autocomplete>

            <!-- employee -->
            <search-employee
              v-if="particular==='attendance-dtr-semi-monthly' || particular==='attendance-dtr-monthly'"
              :employeeId="employee"
              :errorMessage="formErrorFields.employeeErrorMessage"
              @outputId="setEmployeeId"
            ></search-employee>

            <!-- payroll period -->
            <v-dialog
              ref="dialog"
              v-model="modalPayrollPeriod"
              :return-value.sync="payrollPeriodValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :loading="payrollPeriodLoading"
                  v-model="payrollPeriod"
                  label="Payroll Period"
                  outlined
                  dense
                  placeholder="Payroll Period"
                  v-bind="attrs"
                  v-on="on"
                  autocomplete="off"
                  :append-icon="icon.calendar"
                  :class="formErrorFields.payrollPeriodErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.payrollPeriodErrorMessage"

                  @click="formError().remove('payrollPeriod', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="payrollPeriod"
                scrollable
                :type="particular==='attendance-dtr-monthly' ? 'month' : 'date'">
                <v-btn text color="primary" @click="modalPayrollPeriod=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalPayrollPeriod=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-btn
              color="primary"
              :loading="loading"
              @click="generate">
              Generate
            </v-btn>
            <v-btn
              outlined
              class="mx-2"
              @click="reset"
            >
              Reset
            </v-btn>
          </v-form>
        </v-card-text>

        <snackbar
          v-if="message.length > 0"
          :show="message.length > 0"
          :text="message"
          :color="messageStatus"
          @close="message=''"
        >
        </snackbar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCalendarOutline, mdiInformationOutline } from '@mdi/js'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'
import blob from '@/composables/blob'
import fnx from '@/functions/fn'
import SearchEmployee from '@/components/SearchEmployee.vue'

export default {
  metaInfo: {
    title: 'Attendance Summary',
  },

  components: {
    snackbar,
    SearchEmployee,
  },

  setup() {
    const employee = ref(null)
    const loading = ref(false)
    const icon = { calendar: mdiCalendarOutline, info: mdiInformationOutline }
    const payrollPeriodValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const activePayrollPeriod = ref('')
    const payrollPeriodLoading = ref(false)
    const payrollPeriod = ref('')
    const modalPayrollPeriod = ref(null)
    const viewAs = ref('pdf')
    const particular = ref('All')
    const formLoading = ref(false)
    const formErrorFields = ref({
      payrollPeriodErrorMessage: '',
      particularErrorMessage: '',
    })
    const message = ref('')
    const messageStatus = ref('')
    const error = ref('')
    const reportData = ref(null)

    const generate = async () => {
      const formData = {
        employee: employee.value,
        particular: particular.value.toLowerCase(),
        payrollPeriod: payrollPeriod.value,
        viewAs: viewAs.value,
      }

      let mimetype = ''
      if (viewAs.value === 'pdf') {
        mimetype = 'application/pdf'
      } else {
        mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }

      await blob(`${process.env.VUE_APP_URI}/api/payroll/reports/attendance-summary/${fnx.base64_encode(formData)}`, reportData, formLoading, error)
      if (error.value.data !== undefined) {
        message.value = error.value.data.message
        messageStatus.value = error.value.status
      } else {
        console.log(reportData.value)
        const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
        window.open(url, '_blank')
      }
    }

    const reset = () => {
      viewAs.value = 'pdf'
      particular.value = ''
      payrollPeriod.value = ''
    }

    const getPayPeriod = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/payroll-periods/get-active`, activePayrollPeriod, payrollPeriodLoading, error)
      if (error.value.data !== undefined) {
        message.value = error.value.data.message
        messageStatus.value = error.value.status
      } else {
        payrollPeriod.value = activePayrollPeriod.value.end
      }
    }

    getPayPeriod()

    const setEmployeeId = val => {
      employee.value = val
    }

    return {
      payrollPeriodLoading,
      payrollPeriod,
      modalPayrollPeriod,
      payrollPeriodValue,
      viewAs,
      particular,
      icon,
      formLoading,
      reset,
      generate,
      loading,
      formErrorFields,
      formError,
      message,
      messageStatus,
      setEmployeeId,
      employee,
    }
  },
}
</script>
